
import AbstractComponentVue from '../services/AbstractComponentVue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {IAccountIdentity} from '@/services/dto/IDtos';
import AdminRightsTooltip from '@/components/AdminRightsTooltip.vue';
import {services} from '@/main';
import EditPersonnal from '@/components/personnal-info/EditPersonnal.vue';
import {IUserResponse} from '@/services/dto/IUserResponse';
import {IUserService} from '@/services/dto/IUserService';
import AlertService from '@/utils/AlertService';
import DisplayEnumUtils from '@/utils/DisplayEnumUtils';

@Component({
	components: {
		AdminRightsTooltip,
		EditPersonnal,
	},
})
export default class UserCard extends AbstractComponentVue {

  @Prop()
  private accountId!: number;

  // Propriété non obligatoire à watcher le changement de valeur pour lancer la méthode reloadUserCardInfo.
  @Prop()
  private refresh!: boolean;


  private accountIdentity: IAccountIdentity = {
    accountId: 0,
    admin: false,
    entityName: '',
    firstName: '',
    lastName: '',
    roleType: '',
  };
  private displayEnumUtils = DisplayEnumUtils;

  private frontService: IUserService = {} as IUserService;
  private mailService: IUserService = {} as IUserService;
  private balladeService: IUserService = {} as IUserService;
  private visioService: IUserService = {} as IUserService;

  private login: string = '';

  private isAccountInProgress: boolean = false;
  private isAdminOrNotaire: boolean = this.$store.getters.notaireOrAdmin;
  private newAdminState: boolean = false;
  private infoUserAreLoading: boolean = true;


  /**
   * Méthode appelée quand la valeur de la Prop refresh est changée (là où Usercard est injecté)
   */
  @Watch('refresh')
  private reloadUserCardInfo(): void {
    this.loadUser(this.accountId);
  }

  private editionIdentity(): void {
    this.$store.commit('loadIdentity', true);
    setInterval(() => {
      this.showModal('editIdentity');
    }, 100);
  }

  private created() {
    this.loadUser(this.accountId);
  }

  private activateAdminRight() {
    this.newAdminState = true;
    this.sweetAlertConfirm('Confirmez-vous l\'activation des droits administrateur ?');
  }

  private deactivateAdminRight() {
    this.newAdminState = false;
    this.sweetAlertConfirm('Confirmez-vous la désactivation des droits administrateur ?');
  }

  private sweetAlertConfirm(popupActionText: string) {
    AlertService.customAlert(
        'Droits administrateur',
        popupActionText,
        'info',
        'Confirmer',
        true,
        this.updateAdminRight,
        true,
    );
  }

  /**
   * Permet de mettre à jour les droits administratifs
   */
  private updateAdminRight(): void {
    const accountId: number = this.accountIdentity.accountId;
    this.showModal('modal-traitement-cours');
    services.restService.callPatchWithContentType('/api/v1/accounts/' + accountId, this.newAdminState ?
        'true' : 'false', 'application/json; charset=UTF-8').then((response: any) => {

      if (typeof response !== 'undefined' && response.status === 200) {
        AlertService.success(this.newAdminState ? 'Les droits administrateur ont été activés' :
            'Les droits administrateur ont été désactivés.', 'Fermer');
        this.loadUser(accountId);
      } else {
        AlertService.error('Une erreur s\'est produite pendant la mise à jour des droits administrateur');
      }
    }).finally(() => {
      this.hideModal('modal-traitement-cours');
    });
  }

  private loadUser(id: number): void {
    if (this.$store.getters.fullUser.accountId === id) {
      // Si il s'agit de l'utilisateur en cours on charge les infos user depuis le store
      this.loadAttrPageFromUserResponse(this.$store.getters.fullUser);
    } else {
      // Si il s'agit pas de utilisateur actuelment connecter on ac
      services.restService.callGet('/api/v1/accounts/' + id).then((res) => {
        this.loadAttrPageFromUserResponse(res.data);
      });
    }
  }

  private loadAttrPageFromUserResponse(userResponse: IUserResponse): void {
    if (typeof userResponse.userServices !== 'undefined') {
      userResponse.userServices.forEach((x) => {
        if (x.serviceType === 'MER') {
          this.frontService = x;
        } else if (x.serviceType === 'MAIL') {
          this.mailService = x;
        } else if (x.serviceType === 'BALLADE') {
          this.balladeService = x;
        } else if (x.serviceType === 'VISIO_SOFT') {
          this.visioService = x;
        }
      });
    }
    this.accountIdentity.accountId = userResponse.accountId;
    this.accountIdentity.firstName = userResponse.firstName as string;
    this.accountIdentity.lastName = userResponse.lastName as string;
    this.accountIdentity.roleType = userResponse.authorities ? userResponse.authorities[0] : '';
    this.accountIdentity.admin = userResponse.admin;
    this.accountIdentity.entityName = userResponse.entityName as string;
    this.login = this.frontService.login;
    this.isAccountInProgress = this.mailService.state === 'BEING_VALIDATED'
        || this.balladeService.state === 'BEING_VALIDATED' || this.visioService.state === 'BEING_VALIDATED';
    this.infoUserAreLoading = false;
  }

  get fullName(): string {
    let fullname: string = '';
    if (this.accountIdentity.entityName) {
      fullname = this.accountIdentity.entityName.charAt(0).toUpperCase();
      fullname += this.accountIdentity.entityName.substring(1).toLowerCase();
    } else {
      if (typeof this.accountIdentity.firstName !== 'undefined') {
        fullname += this.accountIdentity.firstName.charAt(0).toUpperCase();
        fullname += this.accountIdentity.firstName.substring(1).toLowerCase();
        fullname += ' ';
      }
      fullname += (typeof this.accountIdentity.lastName !== 'undefined') ? this.accountIdentity.lastName.toUpperCase() : '';
    }
    return fullname;
  }

  private identityModified(accountId: number) {
    this.$emit('identity-changed', accountId);
  }
}
