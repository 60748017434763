import {EMPTY_FORM_VALUE, FormFieldValue, IAccountIdentity} from '@/services/dto/IDtos';

export class IdentityChangeForm {
    // Propri�t�s
    public firstName: FormFieldValue = EMPTY_FORM_VALUE;
    public lastName: FormFieldValue = EMPTY_FORM_VALUE;
    public role: FormFieldValue = EMPTY_FORM_VALUE;
    public entityName: FormFieldValue = EMPTY_FORM_VALUE;
    public type: FormFieldValue = EMPTY_FORM_VALUE;

    // Constructeur
    constructor(account: IAccountIdentity) {
        const firstName = account.firstName ? account.firstName : '';
        this.firstName = {
            value: firstName,
            isError: !(firstName.trim().length > 0),
        };
        const lastName = account.lastName ? account.lastName : '';
        this.lastName = {
            value: lastName,
            isError: !(lastName.trim().length > 0),
        };
        const entityName = account.entityName ? account.entityName : '';
        this.entityName = {
            value: entityName,
            isError: !(entityName.trim().length > 0),
        };
        const roleType = account.roleType ? account.roleType : '';
        this.role = {
            value: roleType,
            isError: !(roleType.trim().length > 0),
        };
        const type = account.roleType && account.roleType === 'ROLE_ENTITY' ? true.toString() : false.toString();
        this.type = {
            value: type,
            isError: false,
        };
    }
}
