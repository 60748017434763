
import AbstractComponentVue from '../../services/AbstractComponentVue';
import {Component, Prop, Ref, Watch} from 'vue-property-decorator';
import {services, store} from '@/main';
import {EMPTY_FORM_VALUE, IAccountIdentity} from '@/services/dto/IDtos';
import AlertService from '@/utils/AlertService';
import PatternUtils from '@/utils/PatternUtils';
import {IUserService} from '@/services/dto/IUserService';
import SigSelect from '@/components/user/SigSelect.vue';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import {IdentityChangeForm} from '@/dto/IdentityChangeForm';

@Component({
  components: {SigSelect, SigInputTexte},
})
export default class EditPersonnnal extends AbstractComponentVue {
  // ------------- Propriétés -------------
	@Prop() private login!: string;
	@Prop()	private accountIdentity!: IAccountIdentity;
	@Prop() private mailService!: IUserService;
  @Prop() private balladeService!: IUserService;
  @Prop() private visioService!: IUserService;

  @Ref('refFonction') private refFonction!: SigSelect;

  private namePattern: string =  PatternUtils.SIG_PATTERNS.name;
  private identityChangeForm: IdentityChangeForm = {
    firstName: EMPTY_FORM_VALUE,
    lastName: EMPTY_FORM_VALUE,
    role: EMPTY_FORM_VALUE,
    entityName: EMPTY_FORM_VALUE,
    type: EMPTY_FORM_VALUE,
  };

  // Initialisation des valeurs et labels des selects
  private selectTypeOptions: object[] = [
    {text: 'Entité', value: true},
    {text: 'Personne physique', value: false},
  ];

  private selectFunctionOptions: object[] = [
    {text: 'Collaborateur', value: 'ROLE_SELFCARE_COLLABORATEUR'},
    {text: 'Notaire', value: 'ROLE_SELFCARE_NOTAIRE'},
  ];

  // ------------- Computed Properties -------------
  public get isFormValid(): boolean {
    if (this.isEntity) {
      return !this.identityChangeForm.entityName.isError;
    } else {
      return !(this.identityChangeForm.firstName.isError || this.identityChangeForm.lastName.isError);
    }
  }

  /**
   * Calcul d'après les états des services utilisateurs si l'utilisateur a le droit de changer de type.
   * true => si aucun service utilisateur n'est activé
   * false si au moins un des services utilisateur est activé
   * (un changement de type est le passage de personne à entité ou l'inverse)
   * @private
   */
  private get isTypeChangeable(): boolean {
    return this.balladeService.state !== 'ACTIVE'
          && this.mailService.state !== 'ACTIVE'
          && this.visioService.state !== 'ACTIVE';
  }

  /**
   * Test le type de compte en se basant sur le role de l'account
   */
  private get isEntity(): boolean {
    return this.identityChangeForm.role.value === 'ROLE_ENTITY';
  }

  // ------------- Méthodes Cycle de vie VUEJS -------------
  private created() {
    this.identityChangeForm = new IdentityChangeForm(this.accountIdentity);
    this.identityChangeForm.type.value = this.isEntity.toString();
  }

  // ------------- Méthodes -------------
  /**
   * Méthode appelée à chaque changement d'input du formulaire pour mettre à jour et valider le dto.
   * 1) On met à jour le boolean de validation du formulaire (qui pilote l'activation du bouton submit)
   * 2) On met à jour la bonne valeur dans le dto du formulaire en fonction du divId
   * @param inputDto : Le dto renvoyé par le composant d'input après modification via l'évènement "watchvalue"
   * @param divId : l'id de l'input passé en paramétre à la contruction de l'input et qui permet de retrouver quel
   * champs doit être mis à jour
   * @private
   */
  private updateForm(inputDto: ISigInputTextDto, divId: string) {
    if ( divId === 'firstName' ) {
      this.identityChangeForm.firstName.value = inputDto.value;
      this.identityChangeForm.firstName.isError = inputDto.hasError;
    } else if ( divId === 'lastName' ) {
      this.identityChangeForm.lastName.value = inputDto.value;
      this.identityChangeForm.lastName.isError = inputDto.hasError;
    } else if ( divId === 'entityName' ) {
      this.identityChangeForm.entityName.value = inputDto.value;
      this.identityChangeForm.entityName.isError = inputDto.hasError;
    } else if ( divId === 'role' ) {
      this.identityChangeForm.role.value = inputDto.value;
    } else if ( divId === 'type' ) {
      this.identityChangeForm.type.value = inputDto.value;
      // Obligé de forcer le toString dans l'écriture car certains navigateurs (Chrome) prennent la liberté
      // de convertir les string 'true' en boolean true :(
      if ( inputDto.value.toString() === 'true' ) {
        this.refFonction.setValue('ROLE_ENTITY');
      } else {
        this.refFonction.setValue('ROLE_SELFCARE_COLLABORATEUR');
      }
    }
    // Revalidation du formulaire après mise à jour du dto
    return this.isFormValid;
  }

	/**
  * Fonction d'action du composant :
  * Elle est appelée à la soumission du formulaire pour transmettre au SIG
  * les nouvelles informations d'identité du compte
  * Le role de l'account va jouer est le discriminant :
  */
	private saveIdentity(): void {
    // On revalide le formulaire une fois avant soumission
    // Pour éviter le changement de valeur dans la console navigateur
		if (this.isFormValid) {
      this.showModal('modal-traitement-cours');
      const updatedIdentity: IAccountIdentity = {
        accountId: this.accountIdentity.accountId,
        admin: false,
        entityName: '',
        firstName: '',
        lastName: '',
        roleType: '',
      };
      // Préparation du dto à soumettre au serveur
      if (this.isEntity) {
        updatedIdentity.entityName = this.identityChangeForm.entityName.value;
      } else {
        updatedIdentity.firstName = this.identityChangeForm.firstName.value;
        updatedIdentity.lastName = this.identityChangeForm.lastName.value;
      }
      updatedIdentity.roleType = this.identityChangeForm.role.value;

      // Préparation de la requête POST
      const uri: string = '/api/v1/accounts/' + updatedIdentity.accountId + '/identity';
      const promise: Promise<any> = services.restService.callPatch(uri, updatedIdentity);
      // Envoi de la requête au serveur
      promise.then( (res) => {
        if (typeof res !== 'undefined' && res.status === 200) {
          // Gestion du retour serveur en cas de succes
          const target = window.location.pathname;
          store.commit('saveTargetUrl', target);
          services
            .restService
            .callGet('/api/v1/connected-user/current')
            .then(services.loginService.loadUserPostProcessing);
          AlertService.success('L\'identité de l\'utilisateur a été mise à jour', 'OK');
          this.accountIdentity.roleType = updatedIdentity.roleType;
          if (this.isEntity) {
            this.accountIdentity.entityName = updatedIdentity.entityName;
            this.accountIdentity.firstName = '';
            this.accountIdentity.lastName = '';
          } else {
            this.accountIdentity.firstName = updatedIdentity.firstName;
            this.accountIdentity.lastName = updatedIdentity.lastName;
            this.accountIdentity.entityName = '';
          }
          this.$emit('identitymodified', this.accountIdentity.accountId);
        } else {
          // Gestion du retour serveur en cas d'erreur
          AlertService.error('Une erreur est survenue lors de la mise à jour de l\'identité de l\'utilisateur.', 'OK');
        }
        this.hideModal('editIdentity');
        this.$store.commit('loadIdentity', false);
      }).finally(() => {
      this.hideModal('modal-traitement-cours');
      });
		}
	}

  /**
   * Ferme la présente fenêtre.
   */
  private close(): void {
    this.hideModal('editIdentity');
    this.$store.commit('loadIdentity', false);
  }
}
